
import { Component, Vue } from 'vue-property-decorator';
import TextPage from '../../components/Layout/TextPage.vue';
import PromoBanner from '../../components/PromoBanner.vue';
import Breadcrumbs from '@/components/Layout/Breadcrumbs.vue';

@Component({ components: { TextPage, PromoBanner, Breadcrumbs } })
export default class CookiesPolicy extends Vue {
  created() {
    this.$setPageTitle('Политика Cookies | DOTALOOT');
  }
}
